var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$canAndRulesPass(_vm.user, 'resources_evaluation.see_matrix'))?_c('div',{attrs:{"id":"human-resources-view"}},[_c('human-resources-matrix-header',{staticStyle:{"margin-bottom":"20px"}}),_c('human-resources-matrix-block',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMatrix1),expression:"showMatrix1"}],ref:"human-resources-matrix-1",staticStyle:{"margin-bottom":"20px"},attrs:{"id":"human-resources-matrix-1","name":"Матрица эффективности и потенциала","row-evaluation-type":3,"row-evaluation-type-comment":null,"row-point-name":"evaluate_potential_id","col-evaluation-type":4,"col-evaluation-type-comment":null,"col-point-name":"evaluate_final_efficiency_id","rows-name-configs":{
      1: {evaluationId: 11},
      2: {evaluationId: 10},
      3: {evaluationId: 9},
    },"cols-name-configs":{
      1: {evaluationId: [13, 12]},
      2: {evaluationId: 14},
      3: {evaluationId: 15},
    },"table-cells-config":{
      '1_1': {name: '6. Развивающийся сотрудник', desc:'<b>Описание</b>: Сотрудник, недавно переведенный на данную позицию, или развивающийся сотрудник, который обладает исключительным потенциалом для перевода на более высокую, критическую позицию или способен расширить функционал текущей позиции, но может показывать разный уровень эффективности. <br><b>Рекомендуемое действие по сотруднику</b>: Развитие '},
      '1_2': {name: '8. Перспективный сотрудник', desc: '<b>Описание</b>: Успешный сотрудник, имеющий очень большое стремление, способности и высокий уровень вовлеченности для продвижения на более высокую, критическую позицию. <br><b>Рекомендуемое действие по сотруднику</b>: Развитие / Расширение функционала / Проекты'},
      '1_3': {name: '9. Топ талант', desc: '<b>Описание</b>: Сотрудник стабильно перевыполняет с высоким уровнем качества широкий спектр разнообразных задач. Проявляет большое стремление, способности и высокий уровень вовлеченности для перевода на более высокую, критическую позицию. <br><b>Рекомендуемое действие по сотруднику</b>: Расширение функционала / Проекты'},
      '2_1': {name: '2. Нестабильный сотрудник', desc: '<b>Описание</b>: Сотрудник, недавно переведенный на данную позицию, или развивающийся сотрудник, обладающий стремлением, способностями и вовлеченностью для роста – возможно повышение эффективности при горизонтальном перемещении на том же уровне.<br><b>Рекомендуемое действие по сотруднику</b>: Наблюдение / Поддержка'},
      '2_2': {name: '5. Эффективный сотрудник', desc: '<b>Описание</b>: Успешный стабильный сотрудник, обладающий стремлением, способностями и вовлеченностью для дальнейшего роста на более высокую, критическую позицию или позицию с более широким функционалом.<br><b>Рекомендуемое действие по сотруднику</b>: Развитие'},
      '2_3': {name: '7. Выдающийся сотрудник', desc: '<b>Описание</b>: Лучший сотрудник, который проявляет стремление, способности и вовлеченность для перевода на позицию с более широким функционалом. Текущая позиция может всё ещё предоставлять возможности для роста или развития.<br><b>Рекомендуемое действие по сотруднику</b>: Развитие / Расширение функционала / Проекты'},
      '3_1': {name: '1. Формирующийся талант / Неэффективный сотрудник', desc: '<b>Описание</b>: Сотрудник, недавно переведенный на данную позицию, или непоследовательно развивающийся сотрудник, который может не продемонстрировать стремление, способности и вовлеченность, необходимые для продвижения на более высокую позицию или позицию с более широким функционалом. Сотрудник, не справляющийся с текущими функциональными задачами/ целями.<br><b>Рекомендуемое действие по сотруднику</b>: Наблюдение / Увольнение'},
      '3_2': {name: '3. Стабильный сотрудник', desc: '<b>Описание</b>: Эффективный сотрудник, который может не демонстрировать стремление, способности и вовлеченность, необходимые для продвижения на более высокую позицию или позицию с более широким функционалом.<br><b>Рекомендуемое действие по сотруднику</b>: Наблюдение'},
      '3_3': {name: '4. Ценный сотрудник', desc: '<b>Описание</b>: Сотрудник с высоким уровнем эффективности, который может не демонстрировать стремление, способности и вовлеченность, необходимые для продвижения, или который уже достиг предела своего карьерного потенциала.<br><b>Рекомендуемое действие по сотруднику</b>: Развитие'},
    }},on:{"export-to-pdf":function($event){return _vm.exportToPDF(1)}}}),_c('human-resources-matrix-block',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMatrix2),expression:"showMatrix2"}],ref:"human-resources-matrix-2",attrs:{"id":"human-resources-matrix-2","name":"Матрица риска увольнения и влияния на бизнес","row-evaluation-type":6,"row-evaluation-type-comment":"<b>Влияние на бизнес</b> - оценка влияния ухода сотрудника на бизнес","row-point-name":"evaluate_impact_on_business_id","col-evaluation-type":5,"col-evaluation-type-comment":"<b>Риск увольнения</b> - оценка вероятности того, что сотрудник покинет свою текущую должность в течение следующих 12 месяцев.(пример: демотивация, декретный отпуск, выход на пенсию либо карьерное продвижение на другую позицию)","col-point-name":"evaluate_risk_dismissal_id","rows-name-configs":{
      1: {evaluationId: 19},
      2: {evaluationId: 20},
      3: {evaluationId: 21},
    },"cols-name-configs":{
      1: {evaluationId: 18},
      2: {evaluationId: 17},
      3: {evaluationId: 16},
    },"table-cells-config":{
      '1_1': {name: '6. Низкий риск - Высокое влияние', desc:''},
      '1_2': {name: '8. Средний риск - Высокое влияние', desc: '<b>Рекомендуемое действие по сотруднику</b>: Анализ ситуации и, при необходимости, разработка плана действий по снижению риска и подготовке преемника'},
      '1_3': {name: '9. Высокий риск - Высокое влияние', desc: '<b>Рекомендуемое действие по сотруднику</b>: Анализ ситуации и, при необходимости, разработка плана действий по снижению риска и подготовка преемника'},
      '2_1': {name: '2. Низкий риск - Среднее влияние', desc: ''},
      '2_2': {name: '5. Средний риск - Среднее влияние', desc: ''},
      '2_3': {name: '7. Высокий риск - Среднее влияние', desc: '<b>Рекомендуемое действие по сотруднику</b>: Анализ ситуации и, при необходимости, разработка плана действий по снижению риска и подготовке преемника'},
      '3_1': {name: '1. Низкий риск - Низкое влияние', desc: ''},
      '3_2': {name: '3. Средний риск - Низкое влияние', desc: ''},
      '3_3': {name: '4. Высокий риск - Низкое влияние', desc: ''},
    }},on:{"export-to-pdf":function($event){return _vm.exportToPDF(2)}}}),(_vm.$canAndRulesPass(_vm.user, 'resources_evaluation.matrix_comment_see'))?_c('el-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showComments),expression:"showComments"}],staticStyle:{"margin-top":"20px"}},[_c('comments-block',{attrs:{"disabled":!_vm.$canAndRulesPass(_vm.user, 'resources_evaluation.matrix_comment_create'),"entity-type":"resources_evaluation_matrix","entity-id":parseInt(_vm.$route.params.id),"send-button-text":_vm.$companyConfiguration.i18n.t('card', 'comment_send_button_text', 'Отправить')}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }